import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { withStyles } from '@mui/styles';
import { spacing } from '@mui/system';
import { Loading, BackdropLoading } from 'client/components/Loading';
import AppBar from '@mui/material/AppBar';
import { errorActionCreator } from 'client/store/Error';
import { dataViewActionCreator } from 'client/store/DataView';
import { linkRouteAction } from 'client/store/LinkRoute';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Box, Grid, Typography,} from '@mui/material';
import MuiButton from '@mui/material/Button';
import { styled } from '@mui/styles';
import LayoutObjectHeader from 'client/components/Common/LayoutObjectHeader'
import DataList from './DataList'
import DataViewFilter from './Filters/DataViewFilter'
import { alertHandlerAction } from 'client/store/AlertHandler';

import PDFViewer from './PDFViewer'

import SubmitProcessing from './RecordProcessing/SubmitProcessing';
import Schedule from './Schedule/Schedule'
 

import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import Chart from 'client/components/Charts/Chart';
import { validateField, validateObject } from 'admin/actions/validationAction';
import { recordProcessingCCSchema, recordProcessingECSchema } from './RecordProcessing/ValidationSchema';


import Cookies from 'universal-cookie';
import * as Enums from 'client/components/Common/Enum';
 
import { dataListTheme } from './theme/dataListTheme';
import { DataUtil } from '@syncfusion/ej2-data';
import { getDefaultClientTimeZone, getDeviceType, getNumericFieldFormatSettings, setTabTitle} from 'client/components/Common/Utility';

const Button = styled(MuiButton)(spacing);
const cookies = new Cookies();
 const deviceType = getDeviceType();
const wizardStage = {
    Stage_DATA: 'Data',
    Stage_PAYMENTSELECTION: 'Payment Selection',
    Stage_PAYMENTSUMMARY: 'Payment Summary',
    Stage_MAKEPAYMENT: 'Make Payment',
    Stage_CONFIRMATION: 'Confirmation'
}
const wizardAction = {
    Next: 'Next',
    Back: 'Back',
    Restart: 'Restart'
}
const filterStatus = { Disabled: -1, Hide: 0, Show: 1 }
let currentStage = "";
class dataView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reportId: this.props.reportId === undefined ? 0 : this.props.reportId,
            reportLoadType: this.props.type === undefined ? Enums.ReportObjectLoadType.Report : this.props.type,
            dashboardLoadSubType: this.props.subtype === undefined
                ? Enums.DashboardObjectType.List
                : this.props.subtype,
            customTemplate: this.props.customTemplate === undefined ? "" : this.props.customTemplate,
            showSubReport: false,
            showReport: false,
            showCustom: false,
            hasError: false,
            hasDefinition: false,
            hasData: false,
            definition: {},
            data: [],
            wizard: {
                stage: wizardStage.Stage_DATA,
                selectedRowObject: {},
                processedData: {},
                processing:false,
            },
            editableFieldSum: 0,
            expandedRows: null,
            loadingReport: true,
            loadingReportData: false,
            loadingSubReport: false,
            reportFilters: [],
            globalFilterValue: null,
            showAction: false,
            actionType: null,
            showReportFilter: filterStatus.Disabled,
            subReportParentRowIndex: -1,
            showMessage: false,
            message: '',
            messageButtonStyle: '',
            messageTitle: '',
            isValidReport: true,
            okButtonName: Enums.ButtonName.Ok,
            buttonType: Enums.ButtonType.Ok,
            showMail: false,
            toEmail: '',
            showAttachment: true,
            validationResult: {},
            aggregateList:[],
            loading: true,
            filterClicked: false,
            filterParameter:[],
        }
        this.modifiedRows = [];
    }

    componentDidMount() {
        if (this.props.type !== undefined) {
            if (this.props.subtype !== undefined) {
                this.setState({
                    reportId: this.props.reportId,
                    reportLoadType: this.props.type,
                    dashboardLoadSubType: this.props.subtype
                });
            } else {
                this.setState({
                    reportId: this.props.reportId,
                    reportLoadType: this.props.type,
                    dashboardLoadSubType: Enums.DashboardObjectType.List
                });
            }
            this.getReportDefinition(this.props.reportId)
        } else {
            this.setState({
                reportId: this.props.match.params.id,
                reportLoadType: Enums.ReportObjectLoadType.Report,
                dashboardLoadSubType: Enums.DashboardObjectType.List
            });
            this.getReportDefinition(this.props.match.params.id)
        }
        this.setReportDisplayMode();
    }

    componentDidUpdate(nextProps) {
        if (this.props.type !== undefined) {
            if (this.props.reportId !== nextProps.reportId) {
                this.props.actions.alertHandler.hideAlert();
                if (this.state.hasData || this.state.hasDefinition) {
                    this.modifiedRows = [];
                    this.setState({
                        reportId: this.props.reportId,
                        reportLoadType: this.props.type,
                        dashboardLoadSubType: this.props.subtype,
                        customTemplate: this.props.customTemplate,
                        hasError: false,
                        hasDefinition: false,
                        hasData: false,
                        definition: {},
                        data: [],
                        expandedRows: null,
                        showReportFilter: false,
                        subReportParentRowIndex: -1,
                        showSubReport: false,
                         showReport: false,
                        showCustom: false,
                        loading: true,
                        validationResult: {},
                        filterClicked: false,
                    });
                }
                this.getReportDefinition(this.props.reportId);
            }
        } else {
            if (this.props.match.params.id !== nextProps.match.params.id) {
                this.props.actions.alertHandler.hideAlert();
                if (this.state.hasData || this.state.hasDefinition) {
                    this.modifiedRows = [];
                    this.setState({
                        reportId: this.props.match.params.id,
                        hasError: false,
                        hasDefinition: false,
                        hasData: false,
                        definition: {},
                        data: [],
                        expandedRows: null,
                        showReportFilter: false,
                        subReportParentRowIndex: -1,
                        showSubReport: false,
                        showReport: false,
                        showCustom: false,
                        loading: true,
                        validationResult: {},
                    });
                }
                this.getReportDefinition(this.props.match.params.id);
            }
        }
    }

    isMobileDevice = () => {
        return deviceType !== Enums.DeviceType.Desktop ? true : false;
    }
    isMobileNoTabletDevice = () => {
        return deviceType === Enums.DeviceType.Mobile ? true : false;
    }
    isTablet = () => {
        return deviceType === Enums.DeviceType.Tablet ? true : false;
    }
    setReportDisplayMode() {
        
        if (this.state.reportLoadType === Enums.ReportObjectLoadType.LookUp) {
            
            this.setState({ showReport: true});
        } else if (this.state.reportLoadType === Enums.ReportObjectLoadType.Report) {
           
            this.setState({  showReport: true });

        } else if (this.state.reportLoadType === Enums.ReportObjectLoadType.Dashboard) {

             if (this.state.dashboardLoadSubType === Enums.DashboardObjectType.List) {

                this.setState({ showReport: true, showReportFilter: filterStatus.Show });
            } else if (this.state.dashboardLoadSubType === Enums.DashboardObjectType.CustomPanel) {

                this.setState({ showCustom: true });
            }
        }
    }
    
    getReportDefinition = async (reportId) => {
        currentStage = "";
        let url = `api/ReportDefinition/GetReportDefinition/${reportId}`;
        let versagoCookie = cookies.get('Versago');
       
        if (versagoCookie === undefined) {
            url = `api/ReportDefinition/GetPublicReportDefinition/${reportId}`;
        }
        this.setState({ loading: true, });
        const response = await fetch(url);
        if (response.status === 200) {
            const reportDefinition = await response.json();
            if (this.props.type === Enums.ReportObjectLoadType.Dashboard) {
                reportDefinition.reportFilter = [];
            }

            const aggregateList = reportDefinition.reportOutputFields.filter(column => column.aggregateOption !== Enums.AggregateOptions.None)
            const hasFilter = reportDefinition.reportFilter === undefined
                ? false
                : reportDefinition.reportFilter.length === 0
                    ? false
                    : true
            this.processedDataObj =reportDefinition?.recordProcessingDetails;
            this.processedDataObj.validations = [];
            reportDefinition.editableFieldSettings = reportDefinition.hasRecordSubmission
                ? this.setEditableControlFormat(reportDefinition)
                : {};
           
            this.setState({
                hasError: false,
                hasDefinition: true,
                definition: reportDefinition,
                data: reportDefinition.reportDataSourceType !== Enums.ReportDataSourceType.CrystalReport ? [] : 'data:application/pdf;base64, ',
                wizard: {
                    stage: wizardStage.Stage_DATA,
                    selectedRowObject: {},
                    processedData: cloneDeep(this.processedDataObj),
                    processing: false,
                },
                editableFieldSum: 0,
                expandedRows: null,
                loadingReport: false,
                showReportFilter: hasFilter,
                loadingReportData: !hasFilter,
                loadingSubReport: false,
                reportFilters: [],
                globalFilterValue: null,
                pagination: {
                    enable: reportDefinition.hasPaging,
                    totalRowCount: 0,
                    offset: 0,
                    previousOffset: 0,
                    fetchCount: reportDefinition.pageRowCount,
                    PageOptions: {},//this.getperPageOptions(reportDefinition.pageRowCount)
                },
                subReportParentRowIndex: -1,
                isValidReport: reportDefinition.favoriteStatus === Enums.FavoriteStatus.Delete ? false : true,
                aggregateList: aggregateList,
                filterParameter:[],
            });
            this.initReportFiltersObject(reportId);
            
        }
        else if (response.status === 401) {
            this.props.actions.alertHandler.showErrorMessage("The Data view selected is not available to this account.");
            this.setState({ hasError: true, loading: false, });
        }
        else if (response.status === 404) {
            this.props.actions.alertHandler.showErrorMessage("This data list does not exist");
            this.setState({ hasError: true, loading: false, });
        }
        else if (response.status === 409) {
            this.setState({ hasError: true, definition: { favoriteStatus: Enums.FavoriteStatus.Delete }, removedReportId: reportId, loading: false, });
        }
        else {
            const objectType = this.props.type !== Enums.ReportObjectLoadType.Dashboard  ? "Data view" : this.props.subtype
            this.props.actions.alertHandler.showErrorMessage(`${objectType} loading failed`);
            this.setState({ hasError: true, loading: false, });
        }
    }
    initReportFiltersObject = (reportId) => {
        //console.log("initReportFiltersObject",   this.state.definition)
        let reportFilter = this.state.definition.reportFilter;
        if (reportFilter !== undefined && this.state.definition.reportDataSourceType !== Enums.ReportDataSourceType.CrystalReport &&
            (reportFilter.length === 0 || this.state.reportLoadType !== Enums.ReportObjectLoadType.Report)) {
            this.getReportData(reportId, false, [], this.state.definition);
        }
        else if (reportFilter !== undefined && this.state.definition.reportDataSourceType === Enums.ReportDataSourceType.CrystalReport &&
            (reportFilter.length === 0 || this.state.reportLoadType !== Enums.ReportObjectLoadType.Report)) {
            this.getCrystalReportFile(reportId, false, [], this.state.definition);
        }
        else {
            this.setState({ ...this.state, showReportFilter: filterStatus.Show, loading: false, });
        }

    }
    getDataAboveThreshold = (filterList) => {
        this.setState({ loadingReportData: true, cancelDataLoading: false, loading: true, })
        this.getReportData(this.state.definition.reportId, true, !filterList ? [] : filterList, this.state.definition);
    }
    refineReportWithFilter = (filterList) => {
        this.setState({ loadingReportData: true, cancelDataLoading: false, loading: true, filterClicked: true })
        if (this.state.definition.reportDataSourceType !== Enums.ReportDataSourceType.CrystalReport) {
            
            this.getReportData(this.state.definition.reportId, false, filterList, this.state.definition);
        }
        else {
            this.getCrystalReportFile(this.state.definition.reportId, false, filterList, this.state.definition);
        }

    }

    getReportData = async (reportId, getThresholdData, filterList, reportDefinition, wizardObject, isResetRecordProcessing) => {
        let type = reportDefinition.reportDataSourceType
        // this.setActionParameter();
        let url = `api/ObjectData/GetReportData`;
        let versagoCookie = cookies.get('Versago');
        let filterData = cloneDeep(filterList);
        let parameter = [];
        //Action Parameters
        let search = window.location.search;
        if (search) {
            search = decodeURI(search.slice(2));
        }
        if (versagoCookie === undefined) {
            url = `api/ObjectData/GetPublicReportData`;
        }
        const filterParameter = filterData.filter(function (fieldData, index, arr) {
            if (type === "storedProcedure") {
                return fieldData
            } else {

                return ((fieldData.condition === Enums.ReportFilter.Between && fieldData.value && fieldData.value2) || (fieldData.condition !== Enums.ReportFilter.Between && fieldData.value !== "" && fieldData.value != null));
            }
        });
        
        const response = await fetch(url,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ReportId: reportId,
                    FilterParameter: filterParameter,
                    Pagination: this.state.pagination,
                    Sorting: this.state.sorting,
                    GlobalFilterValue: this.state.globalFilterValue,
                    GetDataAboveThreshold: getThresholdData ? true : false,
                    ActionLinkParameter: search,
                })
            });
        if (response.status === 200) {
            
            const reportDataObject = await response.json();
            currentStage = "";
            const totalRowCount = reportDataObject.TotalRowCount;

            const reportData = DataUtil.parse.parseJson(reportDataObject.result);
            this.setState(preserveState => ({
                ...preserveState,
                hasData: true,
                showSubReport: false,
                filterClicked:false,
                subReportParentRowIndex: -1,
                data: reportData,
                wizard: !wizardObject ? {
                    stage: wizardStage.Stage_DATA,
                    selectedRowObject: {},
                    processedData: cloneDeep(this.state.definition.recordProcessingDetails?.recordProcessingDataDTO),
                    processing: false,
                } : wizardObject,
                editableFieldSum: 0,
                loadingReport: false,
                loadingReportData: false,
                pagination: {
                    enable: this.state.pagination.enable,
                    totalRowCount: totalRowCount,
                    offset: !this.state.pagination.offset ? 0 : this.state.pagination.offset,
                    fetchCount: this.state.pagination.fetchCount,
                    PageOptions: {},//this.getperPageOptions(this.state.pagination.fetchCount)
                },
                validationResult: {},
                Sorting: this.state.sorting,
                loading: false,
                filterParameter: filterParameter,
            }));
            const modifiedRowCount = this.modifiedRows.length;
            if (modifiedRowCount > 0) {
                this.resetSelectedRowAggregate("INITIATED")
                this.modifiedRows = [];
                this.resetSelectedRowAggregate("FINISHED")
            }
            this.setReportDisplayMode();
            if (!isResetRecordProcessing && (reportDefinition.hasUnAuthorizedSubReportList || reportDefinition.hasUnAuthorizedLinkList)) {
                this.props.actions.alertHandler.showWarningMessage("You will not be able to view and interact with some information presented on this page because it is not assigned to your role.");
            }
        }
        else if (response.status === 409) {
            currentStage = "";
            this.setState({ loading: false, filterClicked: false, validationResult: {} });
            this.props.actions.alertHandler.showWarningMessageWithAction("This listing has a lot of records and could take a minute to load. Continue?", [this.getDataAboveThreshold.bind(this, filterList), this.warningMessageCloseAction.bind(this)]);
        }
        else {
            currentStage = "";
            const objectType = this.props.type !== Enums.ReportObjectLoadType.Dashboard ? "Data view" : this.props.subtype
            this.props.actions.alertHandler.showErrorMessage(`${objectType} loading failed`);
            
            this.setState({ hasError: true, loadingReport: false, loading: false, filterClicked: false, validationResult: {} });
            
        }

    }
    warningMessageCloseAction = () => {
        this.setState({ loadingReportData: false, cancelDataLoading: true, loading: false, });
    }
    getCrystalReportFile = async (reportId, getThresholdData, filterList, reportDefinition) => {
       // this.setState({ loadingReportData: true})
        let url = `api/ObjectData/GetCrystalReportFile`;
        const response = await fetch(url,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ReportId: reportId,
                    FilterParameter: filterList.filter(function (fieldData, index, arr) {
                        return fieldData;
                    }),
                    Pagination: this.state.pagination,
                    Sorting: this.state.sorting,
                    GlobalFilterValue: this.state.globalFilterValue,
                    GetDataAboveThreshold: false,
                })
            });
        if (response.status === 200) {
            const reportDataObject = await response.json();
            this.setState(preserveState => ({
                ...preserveState,
                hasData: true,
                showSubReport: false,
                subReportParentRowIndex: -1,
                data: `data:application/pdf;base64, ${reportDataObject.base64Content}`,
                wizard: {
                    stage: wizardStage.Stage_DATA,
                    selectedRowObject: {},
                    processedData: cloneDeep(this.state.definition.recordProcessingDetails.recordProcessingDataDTO),
                    processing: false,
                },
                editableFieldSum: 0,
                loadingReport: false,
                loadingReportData: false,
                pagination: {
                    enable: false,
                    totalRowCount: 0,
                    offset: 0,
                    fetchCount: 0,
                    PageOptions: {},//this.getperPageOptions(this.state.pagination.fetchCount)
                },
                Sorting: this.state.sorting,
                loading: false,
            }));
        }
        else {
            this.props.actions.alertHandler.showErrorMessage("Data view  loading failed");
            this.setState({ loading: false, });
        }
    }

    renderActionBar = (instructions) => {
        var titleButtons = this.getTitleButtons();
        const { classes, type, panelSizeX, panelSizeY } = this.props;
        let grid1Width = titleButtons === undefined ? 100: instructions ? instructions.length >60 ? 70 : instructions.length+10 : 0;
        let grid2Width = titleButtons!==undefined? 100 - grid1Width:0; 

            let paddingTop = this.isMobileNoTabletDevice() ? this.isTablet()?1: 3 : 1;
        let paddingBottom = 3;
        if (type === Enums.ReportObjectLoadType.Dashboard && this.isMobileDevice()) {
            if (panelSizeX === 1) {
                paddingTop = "3%";
                paddingBottom = "5%";
            } else {
                paddingTop = "2%";
                paddingBottom = "3%";
            }
        }
        return (

            <Box id="actionBar" pl={3} pt={paddingTop} pb={paddingBottom} mr={this.isMobileDevice() ? 2 : 7}>
                <Grid container justify="flex-end" className={classes.instructionGrid}>
                    <Grid style={{ maxWidth: `${grid1Width}%` }}>
                        <Box mt={0}>
                            <Typography variant="body1" className={classes.instruction}>{instructions}</Typography>
                            </Box>
                    </Grid>
                    <Grid spacing={1} mt={ 2} justifyContent="flex-end" style={{ maxWidth: `${grid2Width}%` }}>

                        {titleButtons}

                    </Grid>
                </Grid>
            </Box>

        );
    }
    renderReportFilter = () => {
       
        if (this.state.showReportFilter === filterStatus.Show && this.props.type !== Enums.ReportObjectLoadType.Dashboard) {

            return (<DataViewFilter
                isCalendar={this.state.definition.reportFormat === Enums.reportFormat.Calendar?true:false}
                FilterDefinition={this.state.definition.reportFilter}
                FilterData={(this.state.filterParameter ? this.state.filterParameter : [])}
                RefineReportEvent={this.refineReportWithFilter}
                reportId={this.state.definition.reportId}
                ReportDataSourceType={this.state.definition.reportDataSourceType}>  </DataViewFilter>);
        }
    }
    setEditableControlFormat=(definition) => {
        const editableField =
            definition.reportOutputFields.filter(field => field.fieldName ===
                definition.recordProcessingDetails.editableFieldId)[0];
        if (editableField) {
            if (editableField.fieldType === Enums.DbType.Numeric ||
                editableField.fieldType === Enums.DbType.Decimal ||
                editableField.fieldType === Enums.DbType.Int ||
                editableField.fieldType === Enums.DbType.SmallInt) {
                if (editableField.fieldFormat === Enums.FormatType.None) {
                    const settings = {

                        name: editableField.fieldName,
                        caption: "",
                        textAlign: "right",
                        foreColor: 'rgba(0, 0, 0, 0.54)',
                        decimalSeparator: ".",
                        thousandSeparator: ",",
                        prefix: "",
                        decimalScale: 8,
                        fixedDecimalScale: false,
                    }
                    return settings;

                } else {
                    const editControlFormatSettings = getNumericFieldFormatSettings(editableField)
                    return { ...editControlFormatSettings, name: editableField.fieldName, caption: "", textAlign: "right", foreColor: 'rgba(0, 0, 0, 0.54)' }
                }
            }
        }
        
        return {};
    }
    setGridEditableFieldValue = async (changedRows) => {
        //let dataSource = this.state.data;

        this.modifiedRows = changedRows;
        
        if (this.state.definition.recordProcessingDetails.hasAggregate) {
            const selectedRows = await changedRows.filter(function (row, index, arr) {
                return row.VgoRecordSelect;
            });
            const selectedRowTotal = selectedRows.reduce((a, b) => a + b[this.state.definition.recordProcessingDetails.editableFieldId], 0);
            this.props.actions.setSelectedRows.setRows({ rows: changedRows, sum: selectedRowTotal })
        }
    }
    modifyDataSource = () => {
        const editableField = this.state.definition.recordProcessingDetails.editableFieldId;
        let changedRowCount = 0;
        const modifiedData = this.state.data.map((row) => {
            if (changedRowCount !== this.modifiedRows.length) {


                const changedRow = this.modifiedRows.filter(data => data.VgoRowId === row.VgoRowId);
                if (changedRow.length > 0) {

                    row[editableField] = changedRow[0][editableField];
                    row.VgoRecordSelect = changedRow[0].VgoRecordSelect;
                    changedRowCount++;
                }
            }
            return row;
        });

        return modifiedData;
    }
    resetDataSource = () => {
        const modifiedRowCount = this.modifiedRows.length;
        if (modifiedRowCount > 0) {

            this.resetSelectedRowAggregate("INITIATED")

           
            const editableField = this.state.definition.recordProcessingDetails.editableFieldId;
            let changedRowCount = 0;
            const resetData = this.state.data.map((row) => {
                if (changedRowCount !== modifiedRowCount) {


                    const changedRow = this.modifiedRows.filter(data => data.VgoRowId === row.VgoRowId);
                    if (changedRow.length > 0) {
                        if (changedRow[0]["Vgo_OldValue"] !== undefined) {
                            row[editableField] = changedRow[0]["Vgo_OldValue"];
                        }
                        row.VgoRecordSelect = false;
                        changedRowCount++;
                    }
                }
                return row;
            });
            this.modifiedRows = [];

            var currWizard = this.state.wizard;
            var pData = currWizard.processedData;
            pData.bankDetail = {};
            pData.creditCardDetails = {};
            currWizard = { ...currWizard, processedData: pData }
            this.setState(preserveState => ({ ...preserveState, wizard: currWizard, data: resetData, hasData: true }));
            this.resetSelectedRowAggregate("FINISHED")
            
        }
    }
    getSelectedRowsFromSource = async() => {
        const selectedRows = await this.modifiedRows.filter(function (row, index, arr) {
            return row.VgoRecordSelect;
        });

        let selectedRowDetails = { rows: selectedRows, sum: 0 };
        if (this.state.definition.recordProcessingDetails.isProcessPayments) {
            const selectedRowTotal = selectedRows.reduce((a, b) => a + b[this.state.definition.recordProcessingDetails.editableFieldId], 0);
            selectedRowDetails.sum = selectedRowTotal;
        }
        return selectedRowDetails;
    }
    
    handleWizardInputData = (field, value, isValidation) => {
        
        var currentProcessedData = this.state.wizard.processedData;
        let validationResult = this.state.validationResult;
       
        if (field === "paymentMode") {
            currentProcessedData[field] = value;
            currentProcessedData.validations = [];
            validationResult = [];
        } else {

            if (currentProcessedData.paymentMode === Enums.ReportPaymentMode.CC) {
                switch (field) {
                    case "cvv":
                    {
                            currentProcessedData.creditCardDetails[field] = value;
                        break;
                    }
                    default:
                        currentProcessedData.creditCardDetails[field] = value;
                } 
            } //Credit card type
            else if (currentProcessedData.paymentMode === Enums.ReportPaymentMode.ACH) {
              
                switch (field) {
                    case "routingNumber":
                        { 
                            if (value.length <= 9)
                                currentProcessedData.bankDetail[field] = value;
                            break;
                        }
                    case "zip":
                        {
                            if (value.length <= 5)
                                currentProcessedData.bankDetail[field] = value;
                            break;
                        }
                    case "state":
                        {
                            currentProcessedData.bankDetail[field] = value;
                            currentProcessedData.bankDetail["country"] = "USA";
                            break;
                        }
                    default:
                        currentProcessedData.bankDetail[field] = value;
                        break;
                }
            } //Bank details type
        }
        this.setState(preserveState => ({ wizard: { ...preserveState.wizard, processedData: currentProcessedData }, validationResult: validationResult }));
    }
    handleWizardInputBlur = (fieldPath, value, dependencyObject) => {
        var currentProcessedData = this.state.wizard.processedData;
        let validationScheme = recordProcessingCCSchema;
        if (currentProcessedData.paymentMode === Enums.ReportPaymentMode.ACH) {
            validationScheme = recordProcessingECSchema;
        } 
        validateField(validationScheme, fieldPath, value, fieldPath, dependencyObject)
            .then(resp => { 
                let vResult = this.state.validationResult;
                vResult[fieldPath] = resp[fieldPath];
                this.setState({ validationResult: vResult });
            })
    }
    getTitleButtons = () => {
        const { classes } = this.props;
        if (this.state.definition.hasRecordSubmission) {
            let currentwizardStage = this.state.wizard.stage;
            switch (currentwizardStage) {
                case wizardStage.Stage_DATA:
                    {
                        return (
                            <Grid container item spacing={1} justifyContent="flex-end">
                                <Grid item>
                                    <Button className={` ${classes.otherButton_Client}`} variant="defaultContained" disableElevation disabled={this.state.loadingReport} onClick={(e) => this.onStageButtonAction(wizardAction.Restart)}>{this.state.definition.recordProcessingDetails.selectedRowClearCaption}</Button>
                                </Grid> <Grid item>  <Button className={` ${classes.saveButton_Client}`} color="primary" variant="contained" disableElevation disabled={this.state.loadingReport} onClick={(e) => this.onStageButtonAction(wizardAction.Next)} >{this.state.definition.recordProcessingDetails.selectedRowSubmitCaption}</Button>
                                </Grid>
                            </Grid>
                        );
                    }
                case wizardStage.Stage_PAYMENTSELECTION:
                case wizardStage.Stage_PAYMENTSUMMARY:
                    {
                        return (
                            <Grid container item spacing={1} justifyContent="flex-end">
                                <Grid item> <Button className={`${classes.otherButton_Client}`} variant="defaultContained" disableElevation disabled={this.state.loadingReport} onClick={(e) => this.onStageButtonAction(wizardAction.Restart)}>{this.state.definition.recordProcessingDetails.confirmClearCaption}</Button>
                                </Grid> <Grid item>  <Button className={`${classes.otherButton_Client}`} variant="defaultContained" disableElevation disabled={this.state.loadingReport} onClick={(e) => this.onStageButtonAction(wizardAction.Back)}>Back</Button>
                                </Grid> <Grid item> <Button className={`${classes.saveButton_Client}`} color="primary" variant="contained" disableElevation disabled={this.state.loadingReport} onClick={(e) => this.onStageButtonAction(wizardAction.Next)} >{this.state.definition.recordProcessingDetails.confirmSubmitCaption}</Button>
                                </Grid>
                            </Grid>
                        )
                    }
                case wizardStage.Stage_CONFIRMATION:
                    if (this.state.wizard.processedData.processResponse !== undefined) {
                        if (this.state.wizard.processedData.processResponse.hasError) {
                            // if (this.state.definition.recordProcessingDetails.isProcessPayments) {
                            return (
                                <Grid container item spacing={1} justifyContent="flex-end">
                                    <Grid item> 
                                        {!this.state.definition.recordProcessingDetails.isProcessPayments && <Button className={`${classes.otherButton_Client}`} variant="defaultContained" disableElevation disabled={this.state.loadingReport} onClick={(e) => this.onStageButtonAction(wizardAction.Restart)}>Cancel </Button>} </Grid>
                                    <Grid item>  {!this.state.definition.recordProcessingDetails.isProcessPayments && <Button className={`${classes.otherButton_Client}`} variant="defaultContained" disableElevation disabled={this.state.loadingReport} onClick={(e) => this.onStageButtonAction(wizardAction.Back)}>Back</Button>} </Grid>
                                    <Grid item>   {!this.state.definition.recordProcessingDetails.isProcessPayments && <Button className={`${classes.saveButton_Client}`} color="primary" variant="contained" disableElevation disabled={this.state.loadingReport} startIcon={<RefreshOutlinedIcon />} onClick={(e) => this.onStageButtonAction(wizardAction.Next)}>Try Again</Button>} </Grid>

                                    <Grid item>   {this.state.definition.recordProcessingDetails.isProcessPayments && <Button className={`${classes.otherButton_Client_2}`} variant="defaultContained" disableElevation disabled={this.state.loadingReport} onClick={(e) => this.onStageButtonAction(wizardAction.Restart)}>Cancel Payment</Button>} </Grid>
                                    <Grid item>   {this.state.definition.recordProcessingDetails.isProcessPayments && <Button className={`${classes.saveButton_Client_2}`} color="primary" variant="contained" disableElevation disabled={this.state.loadingReport} startIcon={<RefreshOutlinedIcon />} onClick={(e) => this.onStageButtonAction(wizardAction.Back)}>Try Again</Button>} </Grid>
 
                                </Grid>
                            )
                            // }
                            //else {
                            //if (!this.state.definition.recordProcessingDetails.isProcessPayments) { 
                            //    return (
                            //        <Grid container item spacing={1} justifyContent="flex-end">                                       
                            //            <Grid item>  <Button className={`${classes.otherButton_Client}`} variant="defaultContained" disableElevation disabled={this.state.loadingReport} onClick={(e) => this.onStageButtonAction(wizardAction.Back)}>Back</Button>
                            //            </Grid></Grid>
                            //        )
                            //}
                        }
                        else {
                            return (
                                <Grid container item spacing={1} justifyContent="flex-end">
                                    <Grid item>  <Button className={`${classes.otherButton_Client}`} variant="defaultContained" disableElevation disabled={this.state.loadingReport} onClick={(e) => this.onStageButtonAction(wizardAction.Restart)}>Return</Button>
                                    </Grid></Grid>
                            )
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    }
    onStageButtonAction = async (action) => {
        let currentwizardStage = this.state.wizard.stage;
        let nextStage = "";
        let selectedRowObject = await this.getSelectedRowsFromSource(); //rows ,sum
        let hasModifiedData = false;
        let modifiedData = [];
        let isRecordSubmitStage = false;
        this.props.actions.alertHandler.hideAlert();
        currentStage = currentwizardStage;
        switch (currentwizardStage) {
        case wizardStage.Stage_DATA:
        {
            let currWizard = this.state.wizard;

                    if (action === wizardAction.Next) {
                        if (selectedRowObject.rows.length !== 0) {
                            if (this.state.definition.recordProcessingDetails.isProcessPayments) {
                                currWizard.processedData.amount = this.state.editableFieldSum;
                                nextStage = wizardStage.Stage_PAYMENTSELECTION;
                               // this.setState(preserveState => ({ ...preserveState, wizard: currWizard }));
                            }
                            else
                            {
                                currWizard.processedData.amount = this.state.editableFieldSum;
                                if (this.state.definition.recordProcessingDetails.hasConfirm) {
                                    nextStage = wizardStage.Stage_PAYMENTSELECTION;
                                }
                                else
                                {
                                    await this.getPaymentProcessed(selectedRowObject).then(response => {
                                        let processedData = this.state.wizard.processedData;
                                        processedData.processResponse = response;
                                        nextStage = wizardStage.Stage_DATA;
                                        this.setState(prevserveState => ({
                                            loadingReport: false,
                                            loading: false,
                                            wizard: { ...prevserveState.wizard, stage: nextStage, processedData, processing: false, selectedRowObject }
                                        }));
                                        this.resetProcessData(wizardStage.Stage_DATA);
                                    });
                                    isRecordSubmitStage = true;
                                }
                            }
                        } else { 
                            this.props.actions.alertHandler.showErrorMessage("At least one record must be selected to be submitted.");
                            return;
                        } 
                       
                    }
                    else if (action === wizardAction.Restart) {
                        nextStage = wizardStage.Stage_DATA;
                        this.resetProcessData(wizardStage.Stage_DATA);
                        return;
                       // this.setState(preserveState => ({ ...preserveState, wizard: currWizard }));
                    }
                    break;
                }
        case wizardStage.Stage_PAYMENTSELECTION:
                {
                    if (action === wizardAction.Next) {
                        if (this.state.definition.recordProcessingDetails.isProcessPayments) {
                            let dataDTO = this.state.wizard.processedData;
                            let validForNextStage = false;
                            nextStage = wizardStage.Stage_PAYMENTSELECTION;

                    let validationScheme = recordProcessingCCSchema;
                    let validationObject = this.state.wizard.processedData.creditCardDetails;

                            if (dataDTO.paymentMode === Enums.ReportPaymentMode.CC) {

                    } else if (dataDTO.paymentMode === Enums.ReportPaymentMode.ACH) {
                        validationScheme = recordProcessingECSchema;
                        validationObject = this.state.wizard.processedData.bankDetail;
                    }
                    await validateObject(validationScheme, validationObject)
                        .then(resp => {
                            if (resp !== true) {
                                validForNextStage = false;
                                this.setState({ validationResult: resp });
                                this.props.actions.alertHandler.showErrorMessage("Please correct the indicated items");
                            } else {
                                validForNextStage = true;
                            }
                        });

                    if (validForNextStage) {
                        nextStage = wizardStage.Stage_PAYMENTSUMMARY;
                    }
                } else {
                    await this.getPaymentProcessed().then(response => {
                        let processedData = this.state.wizard.processedData;
                        processedData.processResponse = response;
                        nextStage = wizardStage.Stage_CONFIRMATION;
                        this.setState(prevserveState => ({
                            loadingReport: false,
                            loading: false,
                            wizard: { ...prevserveState.wizard, stage: nextStage, processedData, processing: false }
                        }));
                    });
                }
            } else if (action === wizardAction.Restart) {
                nextStage = wizardStage.Stage_DATA;
                        this.resetProcessData();
            } else if (action === wizardAction.Back) {
                nextStage = wizardStage.Stage_DATA;
                modifiedData = this.modifyDataSource();
                        hasModifiedData = true;
                        this.resetProcessSelectionData();
            }
            break;
        }
        case wizardStage.Stage_PAYMENTSUMMARY:
        {
            if (action === wizardAction.Next) {
                if (!this.state.loadingReport) {
                    nextStage = wizardStage.Stage_PAYMENTSUMMARY;
                    this.setState(prevserveState => ({ ...prevserveState, loadingReport: true }));
                }

                await this.getPaymentProcessed().then(response => {
                        let processedData = this.state.wizard.processedData;
                        processedData.processResponse = response;
                        nextStage = wizardStage.Stage_CONFIRMATION;
                        this.setState(prevserveState => ({
                            loadingReport: false,
                            loading: false,
                            wizard: { ...prevserveState.wizard, stage: nextStage, processedData, processing: false }
                        }));
                    }
                );
            } else if (action === wizardAction.Restart) {
                nextStage = wizardStage.Stage_DATA;
                var currWizard = { ...currWizard, processedData: {} }
                this.setState(preserveState => ({ ...preserveState, wizard: currWizard }));
                this.resetProcessData();

            } else if (action === wizardAction.Back) {
                if (this.state.definition.recordProcessingDetails.hasConfirm) {
                    nextStage = wizardStage.Stage_PAYMENTSELECTION;
                } else {
                    nextStage = wizardStage.Stage_DATA;
                    modifiedData = this.modifyDataSource();
                    hasModifiedData = true;
                }
            }
            break;
        }
        case wizardStage.Stage_CONFIRMATION:
        {
            let currWizard = this.state.wizard;
            if (action === wizardAction.Restart) {
                nextStage = wizardStage.Stage_DATA;
                this.resetProcessData();
            } else if (action === wizardAction.Back) {
                if (this.state.definition.recordProcessingDetails.hasConfirm) {
                    nextStage = wizardStage.Stage_PAYMENTSELECTION;
                } else {
                    nextStage = wizardStage.Stage_DATA;
                    modifiedData = this.modifyDataSource();
                    hasModifiedData = true;
                }
            } else if (action === wizardAction.Next) { //to retry record insertion
                if (!this.state.definition.recordProcessingDetails.isProcessPayments) {
                    currWizard.processedData.amount = this.state.editableFieldSum;
                    await this.getPaymentProcessed().then(response => {
                        let processedData = this.state.wizard.processedData;
                        processedData.processResponse = response;
                        nextStage = wizardStage.Stage_CONFIRMATION;
                        this.setState(prevserveState => ({
                            loadingReport: false,
                            loading: false,
                            wizard: { ...prevserveState.wizard, stage: nextStage, processedData, processing: false }
                        }));
                       
                    });
                    isRecordSubmitStage = true;
                    
                }
            }
            break;
        }
        default:
            break;
        }
        if (!isRecordSubmitStage) {
            if (hasModifiedData) {
                this.setState(prevserveState => ({
                    wizard: { ...prevserveState.wizard, stage: nextStage, selectedRowObject, data: modifiedData,}
                }));
            }
            else {
                this.setState(prevserveState => ({
                    wizard: { ...prevserveState.wizard, stage: nextStage, selectedRowObject, }
                }));
            }
        }
    }
    resetProcessObject = (processedData) => {

        processedData.paymentMode = Enums.ReportPaymentMode.CC
        processedData.bankDetail = {
            bankName: "",
            firstName: "",
            lastName: "",
            accountNumber: "",
            address1: "",
            city: "",
            state: "",
            zip: "",
            routingNumber: "",
            accountType: Enums.ReportPaymentAccountType.Business
        };
        processedData.creditCardDetails = {
            cardHolderName: "",
            // cardType: "",
            cardNumber: "",
            expire: "",
            cvv: "",
            address1: "",
            city: "",
            state: "",
            zip: "",
            country: ""
        };
        return processedData;
    }
    resetProcessSelectionData = () => {
        var currWizard = this.state.wizard; 
        var pData = this.resetProcessObject(currWizard.processedData);
        currWizard = { ...currWizard, processedData: pData }
        this.setState(preserveState => ({ ...preserveState, wizard: currWizard, validationResult: {} })); 
    }
    resetProcessData = (curStage) => {
        if (curStage !== wizardStage.Stage_DATA) {
            currentStage = "reset";
        }
        else
        {
            currentStage = wizardStage.Stage_DATA;
        }
        
        this.modifiedRows = [];
        this.resetSelectedRowAggregate("INITIATED")
        var currWizard = this.state.wizard;
        var pData =this. resetProcessObject(currWizard.processedData);
        currWizard = { ...currWizard, processedData: pData }
       
        this.getReportData(this.props.match.params.id, true, (this.state.filterParameter ? this.state.filterParameter : []), this.state.definition, '', true);
        
    }
    resetSelectedRowAggregate = (status) => {
        if (status === "INITIATED") {

            if (this.state.definition.recordProcessingDetails.hasAggregate) {

                this.props.actions.setSelectedRows.setRows({ rows: [], sum: 0, resetStatus: status });
            }
            else {
                this.props.actions.setSelectedRows.setDataResetStatus(status)
            }
        }
        else if (status === "FINISHED") {
            this.props.actions.setSelectedRows.setDataResetStatus(status)
        }


    }
    getPaymentProcessed = async (selectedRowObject) => {
        
        let processedData = this.state.definition.recordProcessingDetails;
        let processResponse = this.state.wizard.processedData.processResponse;
        processedData.recordProcessingDataDTO = this.state.wizard.processedData;
        processedData.recordProcessingDataDTO.amount = this.state.wizard.selectedRowObject.sum;
        processedData.recordProcessingDataDTO.selectedRecords = !selectedRowObject ? JSON.stringify(this.state.wizard.selectedRowObject.rows) : JSON.stringify(selectedRowObject.rows);
        this.setState(prevserveState => ({ loading: true, wizard: { ...prevserveState.wizard, processing: true,  } }));
        const url = `api/ProcessReport/ProcessReportData`; 
        const response = await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(processedData) }); 
        if (response.status === 200) {
            processResponse = await response.json();
            if (!processResponse.hasError) {
                if (!processedData.isProcessPayments) {
                    this.props.actions.alertHandler.showSuccessMessage(processResponse.errorMessage);
                }
            } else { 
                this.props.actions.alertHandler.showErrorMessage(processResponse.errorMessage);
            }
        } else {
            processResponse.hasError = true;
            processResponse.errorMessage = "Please correct the indicated items"
            this.props.actions.alertHandler.showErrorMessage(processResponse.errorMessage);
        }
       
        return processResponse;
    
        
    }
    getTitleText = () => {
       
        let titleObject = {  title: this.state.definition.reportTitle, instruction: this.state.definition.userInstruction     }
        if (this.state.definition.hasRecordSubmission) {
            let currentwizardStage = this.state.wizard.stage;
            switch (currentwizardStage) {
                case wizardStage.Stage_DATA:
                    {
                        titleObject.title = this.state.definition.reportTitle;
                        titleObject.instruction = this.state.definition.userInstruction;
                    }
                    break;
                case wizardStage.Stage_PAYMENTSELECTION:
                case wizardStage.Stage_PAYMENTSUMMARY:
                    {
                        if (this.state.definition.recordProcessingDetails.hasConfirm) {
                            titleObject.title = this.state.definition.recordProcessingDetails.confirmScreenTitle;
                            titleObject.instruction = this.state.definition.recordProcessingDetails.confirmInstructions;
                        } else {
                            titleObject.title = this.state.definition.reportTitle;
                            titleObject.instruction = this.state.definition.userInstruction;
                        }
                    } break;
                case wizardStage.Stage_CONFIRMATION:
                    if (this.state.definition.recordProcessingDetails.hasConfirm) {
                        if (this.state.definition.recordProcessingDetails.isProcessPayments) {
                            titleObject.title = "Payment Summary";
                            titleObject.instruction = "";
                        } else {
                            titleObject.title = this.state.definition.recordProcessingDetails.confirmScreenTitle;
                            titleObject.instruction = this.state.definition.recordProcessingDetails.confirmInstructions;
                        }
                    } else {
                        titleObject.title = this.state.definition.reportTitle;
                        titleObject.instruction = this.state.definition.userInstruction;
                    }
                    break;
                default:
                    break;
            }
        }
        return titleObject;
    }
    showConfirmActionLink = (history, isClose) => {
        if (isClose) {
            this.closeActionLinkWindow(isClose)
        }
        else {
            this.backActionLinkWindow(history)
        }
        
    }
    closeActionLinkWindow = (isClose) => {
        window.close();

    }
    backActionLinkWindow = (history) => {
        history.goBack();

    }
    pushActionLinkToHistory = (link) => {
        this.props.actions.linkRouteAction.setLinkRoute(true, link);
    }

    getObjectDetailsForFavorite = (isValidReport) => {
        let objectDetails = {
            objectType: Enums.MenuType.Report,
            objectName: '',
            objectId: 0,
            hasChart: false,
            hasPayment: false,
            favoriteStatus:'',
        }
        if (this.state.hasDefinition) {
            if (this.state.definition.reportFormat === Enums.reportFormat.Calendar) {
                objectDetails.objectType = Enums.MenuType.Calendar;
            }
            else if (this.state.definition.hasRecordSubmission) {
                if (this.state.definition.recordProcessingDetails.isProcessPayments) {
                    objectDetails.hasPayment = true;
                }
                objectDetails.objectType = Enums.MenuType.RecordProcessing;
            }
            else if (this.state.definition.chartDetails.length >0){
                objectDetails.hasChart = true;
            }
            objectDetails.favoriteStatus = this.state.definition.favoriteStatus;
        }
        if (isValidReport) {
            objectDetails.objectName = this.state.definition.reportTitle;
            objectDetails.objectId = this.state.definition.reportId;
        }
        else if (parseInt(this.props.match.params.id) === parseInt(this.state.removedReportId)) {
            objectDetails.objectName = '';
            objectDetails.objectId = 0;
            objectDetails.removedObjectId = parseInt(this.state.removedReportId);
            objectDetails.favoriteStatus = this.state.definition.favoriteStatus;
        }
        return objectDetails;
    }

handleAlertHandlerMessage=(status,message) => {

    if (status) {
        this.props.actions.alertHandler.showSuccessMessage(message)
    } else {
        this.props.actions.alertHandler.showErrorMessage(message)
    }
}
    
    render() {
        let render = false;
        
        let renderActionBar = true;
        if (this.state.hasDefinition && this.props.type) {
            render = true;
            if (this.props.subtype === Enums.DashboardObjectType.Chart) {
               renderActionBar = false;
            }
        }
        else if (this.state.hasDefinition && (this.state.definition.reportId == this.props.match.params.id)) {
            render = true;
        }
        let titleObject = this.getTitleText();
      
        if (titleObject.title && this.props.type !== Enums.MenuType.Dashboard) {
            setTabTitle(titleObject.title);
        }
        const objectDetails = !this.props.type ? this.getObjectDetailsForFavorite(render) : {};
        return (
            <Box pt={0} height="100%">
                <BackdropLoading loading={this.state.loading} isDashboard={this.props.panelId? true: false }/>
                <Grid container id="dataViewRoot" style={{ height: "100%", overflow: "hidden" }}>
                    {(this.state.hasDefinition || objectDetails.removedObjectId) && !this.props.type &&

                        <Grid item xs={12} >
                            <LayoutObjectHeader loadType={this.props.match.params.loadType} title={titleObject.title} pageAction={this.showConfirmActionLink}
                                objectDetails={objectDetails}
                                favoriteStatus={objectDetails.favoriteStatus} />
                        </Grid>
                    }
                        <Grid item xs={12} >
                            {(this.state.hasDefinition && renderActionBar) && this.renderActionBar(!this.props.type ? titleObject.instruction : titleObject.title)}
                        </Grid>
                    
                    
                        <Grid item xs={12} >
                            {this.state.wizard.stage === wizardStage.Stage_DATA && this.renderReportFilter()}
                        </Grid>
                    
                    {(this.props.panelId) ?

                            (this.props.subtype === Enums.DashboardObjectType.Chart && this.state.hasDefinition && this.state.hasData && this.state.definition.chartDetails.length > 0 ) &&
                       
                            <Grid item xs={12} height="100%" >

                                <Chart chartDetails={
                                    this.state.definition.chartDetails} definition={this.state.definition} chartId={this.props.objectId} data={this.state.data} deviceType={deviceType} dashboardPanelId={this.props.panelId}
                                    panelSizeX={this.props.panelSizeX}
                                    panelSizeY={this.props.panelSizeY} />

                            </Grid>
                        
                        :
                        (this.state.hasData && this.state.definition.chartDetails.length > 0) &&
                       
                        <Grid item xs={12} height="100%" >
                           
                                <Chart chartDetails={
                                    this.state.definition.chartDetails} definition={this.state.definition} chartId={this.props.objectId} data={this.state.data} deviceType={deviceType} dashboardPanelId={this.props.panelId}
                                    panelSizeX={this.props.panelSizeX}
                                    panelSizeY={this.props.panelSizeY} />
                           
                            </Grid>
                        
                    }
                    
                        {(!this.props.type || (this.props.subtype === Enums.DashboardObjectType.List || this.props.subtype === Enums.DashboardObjectType.Schedule) ) &&
                        
                        <Grid id="idDataView" item xs={12}  height="100%">
                            {this.state.wizard.processing && <Loading  />}
                        {(render && this.state.hasData && this.state.definition.reportDataSourceType !== Enums.ReportDataSourceType.CrystalReport && this.state.definition.reportFormat === Enums.reportFormat.Regular) && this.getComponentForStage()}

                        {(render && this.state.hasData && this.state.definition.reportFormat === Enums.reportFormat.Calendar) &&
                                <Schedule schedulerdata={this.state.data}
                                    definition={this.state.definition}
                                    showErrorMessage={this.props.actions.alertHandler.showErrorMessage}
                                    height={this.state.definition.reportFilter.length === 0 ? 840 : 640}
                                    pushActionLinkToHistory={this.pushActionLinkToHistory}
                                    titleFieldCount={this.state.definition.calendar.titleFields.filter(title => title.title || title.fieldName).length}
                                    timezone={this.state.definition.calendar.clientTimeZone ? this.state.definition.calendar.clientTimeZone : getDefaultClientTimeZone()}
                                    componentKey={Math.random()}
                                    loadingEventData={this.state.loadingReportData}
                                    isFromDashboard={!(!this.props.type)}
                                    elementId={!this.props.type ? this.state.reportId : this.props.panelId}
                                    panelSizeX={this.props.panelSizeX}
                                    panelSizeY={this.props.panelSizeY}
                                    handleAlertHandlerMessage={this.handleAlertHandlerMessage}
                                    />}

                                {( this.state.hasData && this.state.definition.reportDataSourceType === Enums.ReportDataSourceType.CrystalReport) &&
                                    <PDFViewer height={this.state.definition.reportFilter.length === 0 ? 840 : 640} fileName={this.state.definition.reportName} data={this.state.data} />}
                                {((this.state.loadingReportData) && this.state.definition.reportDataSourceType === Enums.ReportDataSourceType.CrystalReport) && <Loading />}
                            </Grid>
                           
                        }
                            
                   
                  </Grid>
            </Box>

        );
    }
    
    getComponentForStage = () => {
        //console.log("getComponentForStage"+this.state.wizard.processing);
        let currentwizardStage = this.state.wizard.stage;
      
        switch (currentwizardStage) {
            case wizardStage.Stage_DATA:
                return (
                    <Box height="100%">
                        <DataList data={this.state.data} filterClicked={this.state.filterClicked}
                            definition={this.state.definition}
                            setGridEditableFieldValue={this.setGridEditableFieldValue}
                            showErrorMessage={this.props.actions.alertHandler.showErrorMessage}
                            gridScrollPosition={this.state.gridScrollPosition}
                            modifiedRows={this.modifiedRows}
                            isFromDashboard={!(!this.props.type)}
                            aggregateList={this.state.aggregateList}
                            alertHandler={this.props.actions.alertHandler}
                            pushActionLinkToHistory={this.pushActionLinkToHistory}
                            loadingReportData={this.state.loadingReportData}
                            cancelDataLoading={this.state.cancelDataLoading}
                            panelSizeX={this.props.panelSizeX}
                            panelSizeY={this.props.panelSizeY}
                            currentStage={currentStage}
                    /></Box>)

            case wizardStage.Stage_PAYMENTSELECTION:
                
                return (<SubmitProcessing
                    ref="payform"
                    showOption={"Payment"}

                    recordProcessingDetails={this.state.definition.recordProcessingDetails}
                    dataDefinition={this.state.definition.reportOutputFields}
                    selectedData={this.state.wizard.selectedRowObject.rows}
                    dataSum={this.state.wizard.selectedRowObject.sum}
                    recordProcessOutput={this.state.wizard.processedData}
                    handleWizardInputData={this.handleWizardInputData}
                    validationObject={this.state.validationResult}
                    handleWizardInputBlur={this.handleWizardInputBlur}
                    classes={this.props.classes}
                    
                />)
            case wizardStage.Stage_PAYMENTSUMMARY:
                 
                return (<SubmitProcessing
                    showOption={"Summary"}
                    
                    recordProcessingDetails={this.state.definition.recordProcessingDetails}
                    dataDefinition={this.state.definition.reportOutputFields}
                    selectedData={this.state.wizard.selectedRowObject.rows}
                    dataSum={this.state.wizard.selectedRowObject.sum}
                    recordProcessOutput={this.state.wizard.processedData}
                    handleWizardInputData={this.handleWizardInputData}
                    handleWizardInputBlur={this.handleWizardInputBlur}
                    classes={this.props.classes}
                />)
            case wizardStage.Stage_CONFIRMATION:
              
                return (<SubmitProcessing
                    showOption={"Confirmation"}
                    
                    recordProcessingDetails={this.state.definition.recordProcessingDetails}
                    dataDefinition={this.state.definition.reportOutputFields}
                    selectedData={this.state.wizard.selectedRowObject.rows}
                    dataSum={this.state.wizard.selectedRowObject.sum}
                    recordProcessOutput={this.state.wizard.processedData}
                    handleWizardInputData={this.handleWizardInputData}
                    handleWizardInputBlur={this.handleWizardInputBlur}
                    classes={this.props.classes}
                />)
           
            default:
                break;
        }
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            errorActionCreator: bindActionCreators(errorActionCreator, dispatch),
            alertHandler: bindActionCreators(alertHandlerAction, dispatch),
            setSelectedRows: bindActionCreators(dataViewActionCreator, dispatch),
            linkRouteAction: bindActionCreators(linkRouteAction, dispatch),
        }
    };
}
export default connect(null,
    mapDispatchToProps
)(withStyles(dataListTheme)(dataView));
