import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { displayEventTheme } from 'client/components/DataView/theme/dataListTheme';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import ColumnTemplate from 'client/components/DataView/ColumnTemplate';
import * as Enums from 'client/components/Common/Enum';
import { formatValue, getDeviceType, getEncryptDecrypt} from 'client/components/Common/Utility';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, Typography, } from '@mui/material';
import 'client/components/DataView/Schedule/theme/schedule.css';
 
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-calendars/styles/material.css";
import "@syncfusion/ej2-dropdowns/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-splitbuttons/styles/material.css";
import "@syncfusion/ej2-react-schedule/styles/material.css";
import axios from 'axios';
import { callBizweaverAction } from 'client/api/clientActions';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const deviceType = getDeviceType();
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    
    return (
        <DialogTitle className={displayEventTheme.dialogTitle} sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 0,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

class DisplayEvents extends React.Component {

    handleLinkClick = async (actionId, data) => {
        const linkField = this.props.dataDefinition.reportLink.find(field => field.id === actionId)
        switch (linkField.actionType) {
            case Enums.LinkActionType.Forms: {
                var parameter = "";
                linkField.reportLinkDetails.map((rLink) => {
                    const pName = rLink.parameterName;
                    var pValue = rLink.parameterValue;
                    if (!rLink.isStatic) {
                        pValue = data[rLink.parameterValue];
                    }
                    parameter += (parameter.length > 0 ? "&" : '') + pName + "=" + pValue
                })
                if (parameter.length > 0) {
                    await getEncryptDecrypt(parameter).then((response) => {
                        parameter = encodeURI(response);
                    });
                }
                var Link = "/Form/";
                if (linkField.isShowInNewTab === true) {
                    Link += linkField.actionDestinationObjectId + "/1" + (parameter.length > 0 ? "?&" + parameter : '');
                    window.open(Link, "_blank")
                } else {
                    Link += linkField.actionDestinationObjectId + "/2" + (parameter.length > 0 ? "?&" + parameter : '');
                    this.props.pushActionLinkToHistory(Link);
                }
                break;
            }
            case Enums.LinkActionType.Report: {

                var parameter = "";
                linkField.reportLinkDetails.map((rLink) => {
                    const pName = rLink.parameterName;
                    var pValue = rLink.parameterValue;
                    if (!rLink.isStatic) {
                        pValue = data[rLink.parameterValue];
                    }
                    parameter += (parameter.length > 0 ? "&" : '') + pName + "=" + pValue
                })
                if (parameter.length > 0) {
                    await getEncryptDecrypt(parameter).then((response) => {
                        parameter = response;
                    });
                }
                let Link = "/Report/";
                if (linkField.isShowInNewTab === true) {
                    Link += linkField.actionDestinationObjectId + "/1" + (parameter.length > 0 ? "?&" + parameter : '');
                    window.open(Link, "_blank")

                } else {
                    Link += linkField.actionDestinationObjectId + "/2" + (parameter.length > 0 ? "?&" + parameter : '');
                    this.props.pushActionLinkToHistory(Link);
                    
                }
                break;


            }
            case Enums.LinkActionType.OtherURL: {
                var parameter = "";
                linkField.reportLinkDetails.map((rLink) => {
                    const pName = rLink.parameterName;
                    var pValue = rLink.parameterValue;
                    if (!rLink.isStatic) {
                        pValue = data[rLink.parameterValue];
                    }
                    parameter += (parameter.length > 0 ? "&" : '') + pName + "=" + pValue
                })
                const Link = linkField.actionDestination + (parameter.length > 0 ? "?" + parameter : '');
                if (linkField.isShowInNewTab === true) {
                    window.open(Link, "_blank")
                } else {
                    window.open(Link, "_self")
                }
                break;
            }
            case Enums.LinkActionType.BizweaverWebservice: {
                var parameter = "";
                linkField.reportLinkDetails.map((rLink) => {
                    const pName = rLink.parameterName;
                    var pValue = rLink.parameterValue;
                    if (!rLink.isStatic) {
                        pValue = data[rLink.parameterValue];
                    }
                    parameter += (parameter.length > 0 ? "," : '') + pName + ":'" + pValue + "'"
                })
                this.handleBizweaverAction(linkField.actionDestination, linkField.bizweaverTaskId, parameter);
                break;
            }
            case Enums.LinkActionType.CrystalReport: {
                var parameter = "";
                linkField.reportLinkDetails.map((rLink) => {
                    const pName = rLink.parameterName;
                    var pValue = rLink.parameterValue;
                    if (!rLink.isStatic) {
                        pValue = data[rLink.parameterValue];
                    }
                    parameter += (parameter.length > 0 ? "&" : '') + pName + "=" + pValue
                })
                if (parameter.length > 0) {
                    await getEncryptDecrypt(parameter).then((response) => {
                        parameter = encodeURI(response);
                    });
                }
                var Link = "/CrystalReportViewer/";
                if (linkField.isShowInNewTab === true) {
                    Link += `${this.props.dataDefinition.reportId}/${actionId}/1` + (parameter.length > 0 ? "?&" + parameter : '');
                    window.open(Link, "_blank")
                } else {
                    Link += `${this.props.dataDefinition.reportId}/${actionId}/2` + (parameter.length > 0 ? "?&" + parameter : '');
                    this.props.pushActionLinkToHistory(Link);
                }
            }
            default: { break; }
        }
        //console.log("clickin outer component", actionId, data);
    }
    handleBizweaverAction = async (workFlowurl, taskId, parameters) => {

        await callBizweaverAction(workFlowurl, taskId, parameters)
            .then(response => {
                // this.setState({ loadingForm: false })
                if (response.status === 200) {
                   this.props.handleAlertHandlerMessage(true,Enums.BizWeaverMessage.Success)
                }
            }).catch((err) => {

                this.props.handleAlertHandlerMessage(false,"Bizweaver Call Failed")

            });

    }
    isMobileDevice = () => {
        return deviceType != Enums.DeviceType.Desktop ? true : false;
    }
    buildBody = () => {
        if (this.props.seletedEventId !== -1) {

            const data = this.props.data;//.find(({ VgoRowId }) => VgoRowId === this.props.seletedEventId);
            var filterData = {
                ReportId: this.props.dataDefinition.reportOutputFields.reportId,
                FilterParameter: this.props.dataDefinition.reportOutputFields.filter(function (fieldData, index, arr) {
                    return fieldData.value !== "" && fieldData.value != null;
                }),
                Pagination: {}
            };
            var reportOutputFields = this.props.dataDefinition.reportOutputFields.filter(r => r.fieldType === "date" || r.fieldType === "datetime");
            var fielddata = this.props.dataDefinition.reportOutputFields.filter(field => field.isVisible).map((column) => {
                const linkField = this.props.dataDefinition.reportLink.find(field => field.actionLinkField === column.fieldName)
                column.textAlign='left'
                if (linkField) {
                    column.actionId = linkField.id;
                    column.conditionFormula = linkField.conditionFormula;
                }

                return (<Grid container item direction="row" spacing={10.25}   >
                    <Grid item xs={5}>
                        <Typography variant="body2" classes={this.props.classes.evenItemLabel}>{column["displayFieldName"]}</Typography>
                    </Grid>
                    <Grid item xs={7} wrap="wrap">

                        {linkField ? <ColumnTemplate data={data} column={column} dataFilter={filterData} reportOutputFields={reportOutputFields} formatValue={formatValue} handleLinkTemplateClick={this.handleLinkClick} actionType={true} />
                            : <ColumnTemplate data={data} column={column} dataFilter={filterData} showErrorMessage={"failed"} formatValue={formatValue} />}

                    </Grid>
                </Grid>)
            });

            this.props.dataDefinition.reportLink.filter(field => field.actionMode === Enums.ActionMode.NewColumn).map(column => {

                column.actionId = column.id;
                fielddata.push(<Grid container item direction="row" spacing={10.25}   >
                    <Grid item xs={5}>
                        <Typography variant="body2" classes={this.props.classes.evenItemLabel}>{column.actionName}</Typography>
                    </Grid>
                    <Grid item xs={7} wrap="wrap">

                        <ColumnTemplate data={data} column={column} dataFilter={filterData} reportOutputFields={reportOutputFields} formatValue={formatValue} handleLinkTemplateClick={this.handleLinkClick} actionType={true} />


                    </Grid>
                </Grid>)

            });


            return fielddata;
        }
    }

    render() {
        return (

            <BootstrapDialog
                onClose={this.props.handleClose}
                aria-labelledby="customized-dialog-title"
                open={this.props.setOpen}
                PaperProps={{ className: this.props.classes.dialog }}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={this.props.handleClose} className={this.props.classes.dialogTitle}  >
                    <Typography variant="subtitle2">Event Details</Typography>
                </BootstrapDialogTitle>
                    <DialogContent dividers className={this.props.classes.dialogContent} >
                    <Box ml={this.isMobileDevice()?2:3.875} mt={2.125} mr={ 3.875}>
                            <Grid container spacing={3.5}  >
                               
                                {this.buildBody()}
                             </Grid>
                        </Box>
                    </DialogContent> 
                </BootstrapDialog>
         
        );
    }
}
export default withStyles(displayEventTheme)(DisplayEvents);